<section
  class="app-container"
  [ngClass]="{
    small: displayType === DisplayType.SMALL,
    big: displayType === DisplayType.BIG,
  }"
>

  <div class="left-part">
    <app-icon
      [app]="app"
      [displayType]="displayType"
      (appIconClicked)="onAppClicked()"
    />
  </div>

  <div class="right-part">
    <div class="label" (click)="onAppClicked()">
      <div
      [tooltip]="app.title!"
      [toolTipAppfinder]="isAppFinderPage"
      >
        <a  #titleElement
        (click)="onAppClicked()"
          class="title line-clamp-2"
          [ngClass]="{'!cursor-default':clickOnAppBehavior === ClickOnAppBehavior.NO_BEHAVIOR}"
        >
          {{ app.title }}
        </a>
        <a
          (click)="onAppClicked()"
          class="subtitle line-clamp-1"
          [ngClass]="{'!cursor-default':clickOnAppBehavior === ClickOnAppBehavior.NO_BEHAVIOR}"
        >
          {{ app.subtitle }}
        </a>
      </div>
    </div>

    @if (showAddRemoveButton) {
      <div class="add-remove-button">
        <add-remove-app-button
          [isFromDetailPage]="isFromDetailPage"
          [app]="app"
          [recommendSectionname]="recommendSectionName"
        />
      </div>
    }
  </div>

</section>
